import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Extra,
  Para,
  Empty,
  Language
} from "./styles";


const Footer = ({ t }: { t: TFunction }) => {


  return (
    <>
      <FooterSection id="footer">
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Empty />
              <Language>{t("Address")}</Language>
              <Para>Registered Office</Para>
              <Para>John Kennedy, 8,</Para>
              <Para>IRIS HOUSE, Floor 8, Flat/Office 840Α</Para>
              <Para>3106, Limassol, Cyprus</Para>
              <Para><a href="mailto:contact@aplissan.com">contact@aplissan.com</a></Para>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <a href="https://aplissan.com">&copy; 2024  Aplissan Company LTD</a>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
